section#shareholders {
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    opacity: 0.8;
  }

  main {
    padding-top: 210px;
    padding-bottom: 170px;
    background-image: url("../images/en/shareholders/main-green-circle.png"),
      url("../images/en/shareholders/main-yellow-circle.png"),
      url("../images/en/shareholders/frog-bubbles-blurs.png");
    background-position: right bottom, left bottom 100px, right top;
    background-repeat: no-repeat;
    background-size: 170px, 170px, contain;

    .title-container {
      display: flex;
      justify-content: center;

      .title-wrapper {
        flex: 1;
      }

      .frog-container {
        flex: 1;
        display: flex;
        justify-content: center;

        .frog-image-wrapper {
          max-width: 400px;
          text-align: center;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .about-container {
      margin-top: 175px;
      display: flex;
      gap: 40px;

      .title-wrapper {
        flex: 1;
      }

      .description-wrapper {
        flex: 1;
      }
    }
  }

  .news {
    .news-wrapper {
      margin-top: 60px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      .news-item {
        display: flex;
        padding: 40px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 20px;
        border-radius: 32px;
        border: 1px solid #e8e8e8;

        .date {
          display: flex;
          padding: 6px 12px 6px 8px;
          align-items: center;
          gap: 10px;
          border-radius: 40px;
          background: linear-gradient(175deg, #d9f1a8 4.36%, #a3e8ae 95.66%);
          font-size: 12px;
        }

        h3 {
          font-size: 32px;
        }

        p {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .nav-wrapper {
      display: flex;
      gap: 30px;

      .arrow-wrapper {
        cursor: pointer;
      }
    }
  }

  .founders {
    padding-top: 160px;
    background-image: url("../images/en/shareholders/founders-right-blur-background.png"),
      url("../images/en/shareholders/founders-left-blur-background.png");
    background-position: right top -70px, left bottom;
    background-size: 500px, 548px;
    background-repeat: no-repeat;

    header {
      display: flex;

      .text {
        flex: 1;
      }

      .navigation {
        flex: 1;
      }
    }
  }

  .directors {
    padding-top: 160px;
    background-image: url("../images/en/shareholders/directors-right-stars-background.png"),
      url("../images/en/shareholders/directors-blur-background.png");
    background-position: right 150px top 150px, left top -50px;
    background-size: 300px, 548px;
    background-repeat: no-repeat;

    header {
      display: flex;

      .text {
        flex: 1;
      }

      .navigation {
        flex: 1;
      }
    }
  }

  .founders,
  .directors {
    .swiper-wrapper {
      margin-top: 60px;

      .swiper-slide {
        .avatar {
          height: auto;
          width: 100%;
        }

        .body {
          padding: 30px;
          padding-bottom: 0;
          border-radius: 30px;
          border: 1px solid #141414;
          display: flex;
          flex-direction: column;
          gap: 10px;
          min-height: 288px;

          h4 {
            max-width: 200px;
            height: 58px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.6;
          }

          .subtitle {
            display: flex;
            justify-content: space-between;

            .socials {
              display: flex;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  .finance {
    padding-top: 160px;
    background-image: url("../images/en/shareholders/founders-right-blur-background.png"),
      url("../images/en/shareholders/founders-left-blur-background.png");
    background-position: right top, left bottom;
    background-size: 350px, 448px;
    background-repeat: no-repeat;

    header {
      display: flex;

      .text {
        flex: 1;
      }

      .navigation {
        flex: 1;
      }
    }

    .slider-reports-wrapper {
      margin-top: 60px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      .report-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #141414;
        padding-bottom: 20px;

        h4 {
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  section#shareholders {
    main {
      padding-top: 180px;
      padding-bottom: 120px;
      background-image: url("../images/en/shareholders/main-yellow-circle.png"),
        url("../images/en/shareholders/frog-bubbles-blurs.png");
      background-position: left -90px bottom 450px, right top;
      background-size: 170px, contain;

      .title-container {
        flex-direction: column-reverse;

        .frog-container {
          .frog-image-wrapper {
            max-width: 184px;
          }
        }

        .title-wrapper {
          margin-top: 50px;

          h1 {
            text-align: left;
            font-size: 44px;
            font-weight: 500;
          }
        }
      }

      .about-container {
        margin-top: 120px;
        flex-direction: column;

        p {
          font-size: 16px;
        }
      }
    }

    .news {
      .news-wrapper {
        grid-template-columns: 1fr;
      }
    }

    .founders,
    .directors,
    .finance {
      padding-top: 120px;
      background: none;

      header {
        flex-direction: column;

        .navigation {
          display: none;
        }
      }

      .swiper-wrapper {
        flex-direction: column;
        gap: 20px;

        .swiper-slide {
          cursor: initial;
        }
      }

      .slider-reports-wrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
