.phone-slider-wrapper {
  position: relative;
  max-width: 1180px;
  height: 1100px;
  margin: 0 auto;
  text-align: center;

  .bubbles-background {
    top: 180px;
    left: 100px;
    z-index: 110;
    position: absolute;
    max-width: 1024px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .app-description-wrapper {
    position: absolute;
    z-index: 5;
    bottom: 442px;
    left: 0;
    max-width: 308px;
    text-align: right;
    overflow: hidden;

    p {
      text-align: left;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
    }

    img {
      margin-top: 14px;
      margin-right: 20px;
    }
  }

  .swiper {
    height: 100%;
  }

  .blur-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .phone-wrapper {
    z-index: 10;
    position: relative;
    margin: 200px auto 0 auto;
    max-width: 375px;
    height: 812px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .timer {
    z-index: 120;
    user-select: none;
    position: absolute;
    top: 527px;
    left: 50%;
    margin-left: -260px;
    background: #fff;
    border-radius: 50%;
    width: 122px;
    height: 122px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .autoplay-progress svg {
      --progress: 0;
      width: 100%;
      height: 100%;
      stroke-width: 5px;
      stroke-linecap: round;
      stroke: #000;
      fill: none;
      stroke-dashoffset: calc(135.1 * (1 - var(--progress)));
      stroke-dasharray: 135.1;
      transform: rotate(-90deg);
    }
  }

  .footer-carousel {
    z-index: 100;
    position: absolute;
    bottom: 175px;
    width: 100%;

    &::before {
      content: "";
      filter: blur(12px);
      transform: translateZ(0);
      z-index: 110;
      position: absolute;
      top: -30px;
      left: -35px;
      width: 80px;
      height: 150%;
      background: #fff;
    }

    &::after {
      content: "";
      filter: blur(12px);
      transform: translateZ(0);
      z-index: 110;
      position: absolute;
      top: -30px;
      right: -35px;
      width: 80px;
      height: 150%;
      background: #fff;
    }

    .swiper-wrapper {
      justify-content: flex-start;

      .swiper-slide {
        user-select: none;
        width: 247px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .phone-slider-wrapper {
    height: auto;
    margin: 60px 0;

    .bubbles-background {
      display: none;
    }

    .phone-wrapper {
      height: auto;
      padding: 0 60px;
      margin-top: 260px;
    }
    .app-description-wrapper {
      left: 50%;
      margin-left: -154px;
      top: 60px;
      text-align: center;
      p {
        text-align: center;
        font-size: 20px;
      }
      img {
        display: none;
      }
    }
    .timer {
      top: 186px;
      width: 106px;
      height: 106px;
      margin-left: -53px;
    }
    .footer-carousel {
      &::before,
      &::after {
        display: none;
      }
      bottom: 60px;
      .swiper-wrapper {
        .swiper-slide {
          width: 150px;
        }
      }
    }
  }
}
