.sidebar {
  margin-left: 40px;

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 322px;
    max-width: 400px;
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;

    margin-bottom: 20px;
    opacity: 0.5;

    &>div {
      margin-right: 20px;
    }

    &_active {
      font-weight: 500;
      opacity: 1;

      &>div {
        height: 100%;
        width: 2px;
        margin-right: 18px;
        background-color: #349C5D;
        border-radius: 2px;
      }
    }

    &>span {
    }

  }
}