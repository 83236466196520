.search-input {
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  border-radius: 56px;
  padding: 30px 26px;
  display: flex;
  align-items: center;

  &>img {
    width: 24px;
    height: 24px;

    &:nth-child(1) {
      margin-right: 16px;
    }

    &:nth-last-child(1) {
      margin-left: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &>input {
    flex-grow: 1;
    align-self: stretch;
    border: none;
    font-size: 20px;
    font-weight: 300;

    &:focus {
      outline: none;
    }
  }
}