section#news-show {
  padding-top: 150px;

  .back-to-news {
    margin-bottom: 50px;

    a {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      opacity: 0.8;
    }
  }

  .date {
    display: inline-flex;
    padding: 6px 12px 6px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: linear-gradient(175deg, #d9f1a8 4.36%, #a3e8ae 95.66%);
    font-size: 12px;
  }

  h2 {
    margin-top: 30px;
  }

  .body {
    .download {
      // flex: 1;
      // max-width: 248px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-top: 60px;
    }

    .download-app-button:first-of-type {
      margin-bottom: 20px;
      max-width: 177px;
    }

    .download-app-button:last-of-type {
      max-width: 192px;
    }

    .download img {
      width: 20%;
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
      margin: 40px 0;
    }

    p,
    li {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      opacity: 0.8;
    }

    h3 {
      margin-bottom: 40px;
    }

    h4 {
      margin-top: 60px;
      margin-bottom: 40px;
    }

    ul {
      margin-bottom: 60px;

      li {
        list-style: disc;
        margin-top: 30px;
        margin-left: 20px;
      }
    }
  }
}