.how-it-works {
  position: relative;
  margin-top: 180px;

  header {
    z-index: 10;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 79px;
      color: #141414;
      font-weight: 700;
      text-align: center;
    }

    h1 span {
      background: linear-gradient(to right, #349c5d, #b5e89d);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .slider-wrapper {
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    text-align: center;

    .swiper {
      height: 100%;
    }

    .slide-wrapper {
      img {
        width: 100%;
        height: auto;
      }
    }

    .timer {
      z-index: 120;
      user-select: none;
      position: absolute;
      bottom: 100px;
      right: -100px;
      background: #fff;
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;

      span {
        font-size: 12px;
      }

      .progress-wrapper {
        width: 64px;
        height: 64px;
        position: relative;

        .gray-circle {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(20, 20, 20, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .autoplay-progress svg {
          --progress: 0;
          width: 100%;
          height: 100%;
          stroke-width: 1px;
          stroke-linecap: round;
          stroke: #000;
          fill: none;
          stroke-dashoffset: calc(147.6 * (1 - var(--progress)));
          stroke-dasharray: 147.6;
          transform: rotate(-90deg);
        }
      }
    }
  }

  footer {
    background: url("./../../images/en/home/how-it-works/footer/background.jpg")
      center -75px no-repeat;
    background-size: cover;
    padding-top: 200px;

    .card-wrapper {
      height: 600px;
    }
  }
}

@media (max-width: 991.98px) {
  .how-it-works {
    margin-top: 40px;

    footer {
      padding-top: 0;
    }

    header {
      position: initial;

      h1 {
        font-size: 56px;
      }
    }

    .slider-wrapper {
      height: auto;
      margin: 60px 0;

      .slide-wrapper {
        height: auto;
      }

      .progress-container {
        position: absolute;
        z-index: 50;
        bottom: 30px;
        left: 0;
        width: 100%;
        padding: 0 30px;
        justify-content: space-between;
        align-items: center;

        .progress-wrapper {
          width: 145px;
          height: 4px;
          background: #e8e8e8;

          .progress {
            height: 4px;
            background: #000;
          }
        }
      }
    }

    .card-group-wrapper {
      margin-top: 50px;
    }
  }
}
