@import "faq-item";
@import "contact-us-section";

#faq {
  &>div {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    margin-top: 210px;
    width: 100%;

    &>.content {
      width: 850px;

      &>header {
        &>.title-container {
          max-width: 620px;
          margin-bottom: 60px;

          &>.title {
            font-size: 79px;
            font-weight: 700;
            margin-bottom: 50px;

            &>span:last-child {
              background-image: linear-gradient(45deg, #349c5d, #b5e89d);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          &>p {
            font-weight: 300;
          }
        }

        &>.search-input {
          margin-bottom: 60px;
        }
      }

      &>.faq-item {
        margin-bottom: 60px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #faq {
    .sidebar {
      display: none;
    }

    &>div>.content {
      &>header>.title-container>.title {
        font-size: 54px;
      }
    }
  }
}

@media (max-width: 490px) {
  #faq {
    .sidebar {
      display: none;
    }

    &>div>.content {
      width: 100%;

      &>header>.title-container>.title {
        font-size: 44px;
      }
    }
  }

  .search-input>input {
    width: 100%;
  }
}