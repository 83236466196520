#home section.security {
  background: url("./../../images/en/home/security/background.png") left -40px no-repeat;
  background-size: 600px auto;
  padding-top: 200px;

  .section-header {
    margin-bottom: 60px;
  }

  .security-cards {
    display: flex;
    gap: 20px;

    .security-card-wrapper {
      flex: 1;
      padding: 30px;
      border-radius: 40px;
      height: 434px;

      &:nth-of-type(1) {
        background: #f5f9ef;
      }

      &:nth-of-type(2) {
        background: #eff9f9;
      }

      &:nth-of-type(3) {
        background: #f3eff9;
      }

      img {
        width: 96px;
        height: 96px;
      }

      h4 {
        margin-top: 40px;
      }

      p {
        margin-top: 20px;
        color: #313036;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #home section.security {
    .section-header {
      .heading-wrapper {
        h2 {
          line-break: loose;
          word-break: break-all;
        }
      }
    }
    .security-cards {
      flex-direction: column;
    }
  }
}
