#home {
  .business-payments {
    background: url("./../../images/en/home/business-payments/background.jpg")
      left top no-repeat;
    background-size: 427px auto;
    padding-top: 260px;

    .section-header {
      margin-bottom: 60px;
    }
  }
}
