section#contact-us {
  main {
    padding-top: 210px;
    background: url("../images/en/contact-us/main-bg.jpg") right top 80px
      no-repeat;
    background-size: 800px;

    header {
      h1 {
        position: relative;
        font-size: 96px;
        font-weight: 500;

        img {
          width: 170px;
          height: auto;
          margin-top: -40px;
        }
      }
    }

    footer {
      display: flex;
      gap: 60px;
      margin-top: 50px;

      form {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;

        textarea {
          height: 240px;
        }

        .button-container {
          text-align: right;
          display: flex;
          justify-content: flex-end;

          button {
            max-width: 200px;
          }
        }
      }

      .form-info-container {
        flex: 1;

        h4 {
          margin-bottom: 40px;
          font-size: 20px;
          font-weight: 500;
        }

        .address-location-items {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin-bottom: 60px;

          .address-location-wrapper {
            .address {
              font-size: 18px;
              font-weight: 300;
              opacity: 0.8;
            }

            .location {
              display: flex;
              align-items: center;
              gap: 8px;
              margin-top: 8px;

              p {
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .map {
    margin-top: 25px;

    img {
      height: 540px;
      width: 100%;
      object-fit: cover;
      border-radius: 45px;
    }
  }

  .call-back {
    margin-top: 200px;
    text-align: center;
    background: url("../images/en/contact-us/callback-bg.jpg") center top
      no-repeat;
    background-size: cover;

    .call-back-wrapper {
      padding: 100px 0 120px 0;
      max-width: 1440px;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }

    .frog-img-wrapper {
      width: 400px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .arrow-img-wrapper {
      width: 105px;
      position: absolute;
      top: 320px;
      right: 180px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .phone-input-wrapper {
      margin: 40px auto 0 auto;
    }
  }

  .questions {
    padding: 200px 0;
    background: url("../images/en/contact-us/questions-blur.jpg") left center
      no-repeat;
    background-size: 400px;
    margin-bottom: -200px;
  }
}

@media (max-width: 991.98px) {
  section#contact-us {
    main {
      background: none;
      padding-top: 130px;

      header {
        h1 {
          font-size: 60px;

          img {
            width: 110px;
            left: 100px;
          }
        }
      }

      footer {
        flex-direction: column;

        form {
          .button-container {
            button {
              max-width: 100%;
            }
          }
        }
      }
    }

    .call-back {
      margin-top: 100px;

      .call-back-wrapper {
        h2 {
          font-size: 24px;
        }

        .frog-img-wrapper {
          display: none;
        }

        .arrow-img-wrapper {
          width: 70px;
          top: auto;
          bottom: 30px;
          right: 120px;
        }
      }
    }

    .questions {
      padding-top: 100px;

      h1 {
        font-size: 40px;
      }
    }
  }
}
