.mobile-modal {
  display: none;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  padding: 24px 21px;
  border: #000 solid 1px;
  border-radius: 30px;
  margin: 0 auto;

  &>h3 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 322px;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;

    margin-bottom: 10px;
    opacity: 0.5;

    &:last-child {
      margin-bottom: 0;
    }

    &>div {
      margin-right: 20px;
    }

    &_active {
      font-weight: 500;
      opacity: 1;

      &>div {
        height: 100%;
        width: 2px;
        margin-right: 18px;
        background-color: #349C5D;
        border-radius: 2px;
      }
    }

  }
}

.mobile-modal_open {
  display: flex;
  flex-direction: column;
  align-items: left;
}