#home {
  section.interested {
    background: url("./../../images/en/home/interested/background.jpg") right
      top no-repeat;
    background-size: 427px auto;
    padding-top: 120px;
    overflow: hidden;

    .section-header {
      margin-bottom: 60px;
    }

    .articles-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
  }
}

@media (max-width: 991.98px) {
  #home section.interested {
    .articles-container {
      grid-template-columns: 1fr;
    }
  }
}
