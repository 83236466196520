#home section.control {
  background: url("../../images/en/home/control/background.jpg") center top
    no-repeat;
  background-size: cover;
  margin-top: 200px;
  padding: 120px 0;
  overflow: hidden;

  .sections-wrapper {
    display: flex;
    flex-direction: 60px;
  }

  .left-section {
    text-align: center;
    flex: 1;

    img {
      width: 508px;
      height: auto;
    }
  }

  .right-section {
    flex: 1;

    h2 {
      margin-bottom: 40px;

      img {
        width: 72px;
        height: 72px;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      opacity: 0.8;
    }

    .buttons {
      display: flex;
      gap: 20px;
    }
  }
}

@media (max-width: 991.98px) {
  #home section.control {
    margin-top: 120px;
    padding: 60px 0;

    .sections-wrapper {
      flex-direction: column;

      .left-section {
        img {
          width: 100%;
          height: auto;
        }
      }

      .right-section {
        margin-top: 30px;

        .buttons {
          flex-direction: column;
        }
      }
    }
  }
}
