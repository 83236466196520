@import "phone-slider";

@keyframes slide {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-1361px);
  }
}

#home {
  & > header {
    width: 100%;
    overflow: hidden;
    margin-top: 105px;
    .sliding-main-image {
      background-size: 1361px 382px;
      animation: slide 30s linear infinite;
      height: 382px;
      width: 4083px;
      margin-bottom: 35px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 20px;

      a {
        flex: auto;
      }
    }

    .download-section {
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
    }

    .left-download-section {
      max-width: 660px;

      h1 {
        font-size: 79px;
        color: #141414;
        font-weight: 700;
      }

      h1 span {
        background: linear-gradient(to right, #349c5d, #b5e89d);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .right-download-section {
      max-width: 460px;

      p {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #home {
    header {
      .sliding-main-image {
        margin-bottom: 0;
      }
      .download-section {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        & > div {
          h1 {
            font-size: 44px;
          }
          p {
            margin-bottom: 20px;
          }

          .buttons {
            justify-content: flex-start;

            a {
              flex: none;
              display: flex;
              height: 70px;
              border-radius: 70px;

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
