.contact-us-section {
  height: 516px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  & > div:nth-child(2) {
    max-width: 520px;
    margin-right: 55px;

    & > h3 {
      font-size: 50px;
      margin-bottom: 40px;
    }

    & > p {
      font-weight: 300;
      font-size: 18px;
    }
  }

  & > form {
    width: 100%;
    max-width: 620px;

    & > .input-container {
      border-color: gray;
      margin-bottom: 20px;
    }

    & > .textarea-container > textarea {
      border-color: gray;
      margin-bottom: 20px;
    }

    & > .send-btn {
      margin: 0 auto;
      display: flex;
      height: 60px;
      width: 240px;
      background-color: #000;
      border-radius: 40px;
      color: #fff;
      align-items: center;
      justify-content: center;
    }

    & > .twisted-arrow {
      position: absolute;
      width: 80px;
    }
  }
}

@media (max-width: 991.98px) {
  .contact-us-section {
    height: 100vh;
    flex-direction: column;

    & > div:nth-child(2) {
      margin-right: 0;
      text-align: center;

      & > p {
        margin-bottom: 30px;
      }
    }
  }
}
