.article-card-container {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  overflow: hidden;
  padding: 8px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 20px;

    .tag-wrapper {
      display: flex;
      gap: 10px;
      color: #fff;
      font-size: 12px;
      padding: 4px 12px 4px 8px;
      align-items: center;
      border-radius: 40px;
      background: rgba(20, 20, 20, 0.2);
      backdrop-filter: blur(20px);
      transform: translateZ(0);
    }
  }

  .content-wrapper {
    padding: 22px;
    background: #fff;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      color: #313036;
    }
  }
}
