.textarea-container {
  & > textarea {
    min-height: 140px;
    border-radius: 24px;
    border: 1px solid #414141;
    padding: 24px;
    width: 100%;
    resize: none;
    font-size: 20px;
    font-weight: 300;
    background: transparent;

    &:focus {
      outline: none;
    }
  }
}
