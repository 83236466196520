.phone-input-wrapper {
  max-width: 620px;
  padding: 0 10px 0 24px;
  border-radius: 40px;
  border: 1px solid #000;

  &.active {
    .body {
      display: flex;
    }
  }

  &.error {
    border-color: #e84e1e;
  }

  .main {
    display: flex;
    align-items: center;

    form {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .button {
      max-width: 168px;
    }

    input {
      width: 100%;
      height: 80px;
      border: none;
      background: transparent;
      border-radius: 0;
      font-size: 18px;
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }

    .code-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      user-select: none;
      cursor: pointer;
      .code {
        display: flex;
        align-items: center;
        gap: 4px;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          width: 60px;
          font-size: 18px;
        }
      }
    }
  }
  .body {
    padding-bottom: 25px;
    display: none;

    ul {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 16px;
      li {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        user-select: none;
        font-size: 14px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .phone-row-wrapper {
    button {
      margin-top: 30px;
    }
  }
}
