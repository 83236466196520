.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  position: absolute;
  margin-top: 30px;

  nav {
    top: 30px;
    left: 0;
    right: 0;
    z-index: 100;
    position: fixed;

    & > ul {
      display: flex;
      flex-direction: row;

      & > div {
        display: flex;
        flex-direction: row;

        &.expand-block {
          width: 100%;
        }

        & > li {
          &.select-item {
            width: 100%;
          }

          &.lang-select-item {
            width: auto;

            .title {
              padding: 0 18px;
              display: flex;
              flex-direction: row;
            }

            .left-lang {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right: 40px;

              img {
                margin-right: 10px;
                height: 24px;
              }
            }
          }

          & > .nav-item {
            border-radius: 30px;
            display: inline-block;
            min-height: 60px;
            line-height: 60px;
            width: 100%;
            background: rgba(#fff, 0.2);
            backdrop-filter: blur(5px);
            transform: translate3d(0, 0, 0);
          }

          .nav-select {
            border: 1px solid #000;

            &.active {
              background: #000;
              color: #fff;

              .title {
                p {
                  color: #fff;
                }
              }

              .body {
                display: block;
              }
            }

            .title {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              user-select: none;

              p {
                font-size: 14px;
                margin-right: 10px;
                line-height: 60px;
              }
            }

            .body {
              display: none;
              padding: 24px;
              padding-top: 0;

              ul {
                li {
                  line-height: 1;
                  border-top: 1px solid rgba(232, 232, 232, 0.2);

                  &:last-of-type {
                    padding-bottom: 0;
                  }

                  a {
                    cursor: pointer;
                    width: 100%;
                    display: inline-block;
                    padding: 16px 0;
                    color: #fff;
                    font-size: 16px;

                    img {
                      width: 24px;
                      height: 24px;
                      margin-right: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      li.brand {
        a {
          padding: 0 50px;
          background: rgba(52, 156, 93, 1);
          display: flex;
          align-items: center;

          img {
            width: auto;
            height: 28px;
          }
        }
      }

      li.download-btn {
        a {
          text-align: center;
          padding: 0 30px;
          min-width: 200px;
          background: #000;
          white-space: nowrap;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
        }
      }

      li.menu-button,
      li.download-button {
        display: none;

        .nav-item {
          cursor: pointer;
          background: #fff;
          border: 1px solid #000;
          border-radius: 60px;
          height: 60px;
          width: 60px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .header {
    nav {
      top: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      background: rgba(#fff, 0.2);
      backdrop-filter: blur(5px);
      transform: translate3d(0, 0, 0);

      & > ul {
        flex-direction: column;

        .expand-block {
          display: none;

          &.active {
            display: initial;
          }
        }

        & > div {
          align-items: center;
          justify-content: center;

          li {
            .nav-select {
              background: #fff;

              &.active {
                background: #fff;
                color: #141414;

                .title {
                  p {
                    color: #141414;
                  }
                }
              }

              .title {
                justify-content: flex-start;
                padding: 0 24px;

                p {
                  font-weight: 500;
                  font-size: 20px;
                  color: #000;
                }

                svg {
                  display: none;
                }
              }

              .body {
                display: block;

                ul {
                  li {
                    border-top: 0;

                    a {
                      padding: 8px 0;
                      font-size: 14px;
                      color: #000;

                      .icon-image {
                        filter: invert(1);
                      }
                    }
                  }
                }
              }
            }

            &.lang-select-item {
              width: 100%;
            }

            &.download-btn {
              a {
                min-width: auto;
                width: 60px;
                padding: 0;

                img {
                  margin-top: -3px;
                }
              }
              span {
                display: none;
              }
            }
          }
        }

        li.menu-button,
        li.download-button {
          display: initial;

          .nav-item {
            line-height: 56px;
          }
        }

        li.menu-button.active {
          .nav-item {
            background: #000;
          }
        }

        .expand-block {
          flex-direction: column;
        }
      }
    }
  }
}
