.modal-menu-btn {
  display: none;
}

@media (max-width: 991.98px) {
  .modal-menu-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: 20px;
    bottom: 40px;
    z-index: 1000;

    &_open {
      background-color: #D0D0D0;
      border: #000 solid 1px;
    }
  }
}