section#rates {
	padding-top: 150px;

	h2 {
		margin: 30px 0;
		text-align: center;
	}

	p {
		font-size: 18px;
		font-weight: 300;
		line-height: 28px;
		opacity: 0.8;
		text-align: center;
	}

	.table_rate {
		width: 45%;
		margin: 30px 0;
		border-radius: 10px;
		// border: 2px solid #a8e5f4;
		overflow: hidden;

		&.table_eur {
			border: 2px solid #a8e5f4;

			caption {
				background-color: #eff9f9;
			}
		}

		&.table_pln {
			border: 2px solid #ff9a01;

			caption {
				background-color: #fef1e9;
			}
		}

		caption {
			font-size: 20px;
			color: #141414;
			text-align: center;
			font-weight: bold;
			// background-color: #eff9f9;
		}
	}

	table {
		width: 100%;
		caption-side: top;
		text-align: center;
		color: #141414;
	}

	th {
		font-size: 16px;
		font-weight: normal;
		padding: 10px;
	}

	tr {
		border-top: 1px solid #cfcecd;
	}

	td {
		padding: 10px;
	}

	.body {
		@media only screen and (min-width: 768px) {
			.container-table {
				display: flex;
				justify-content: space-around;
				align-items: flex-start;

				&>.table_rate {
						width: calc(45% - 10px);
						margin-top: 40px;
					}
			}
		}

		@media only screen and (max-width: 767px) {
			.container-table {
				flex-direction: column;

				&>.table_rate {
						width: 100%;
					}
			}
		}

	}
}