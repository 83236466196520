.input-container {
  border: 1px solid rgba(#141414, 0.3);
  border-radius: 56px;
  padding: 24px;
  align-items: center;

  & > input {
    flex-grow: 1;
    align-self: stretch;
    border: none;
    font-size: 20px;
    font-weight: 300;
    background: transparent;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}
