@import "fonts";
@import "nav";
@import "home";
@import "faq";
@import "components";
@import "terms-and-privacy";
@import "footer";
@import "blog/article-card.scss";
@import "shareholders";
@import "news";
@import "contact-us";
@import "rates";

$black-color: #141414;

html,
body {
  font-family: "e-Ukraine";
  color: #141414;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #141414;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

.mt-3 {
  margin-top: 30px !important;
}
.mb-3 {
  margin-top: 30px !important;
}
.mt-4 {
  margin-top: 12px !important;
}
.mb-4 {
  margin-bottom: 12px !important;
}
.mt-6 {
  margin-top: 24px !important;
}
.mb-6 {
  margin-bottom: 24px !important;
}
.mt-sm {
  margin-top: 16px !important;
}
.mb-sm {
  margin-bottom: 16px !important;
}
.mb-md {
  margin-bottom: 40px !important;
}
.mt-md {
  margin-top: 40px !important;
}

.fix-blur {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.second-heading {
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
}

.third-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.fourth-heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

h1,
.h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2;
  color: $black-color;

  span {
    background: linear-gradient(to right, #349c5d, #b5e89d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h2,
.h2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
  color: $black-color;
}

h3,
.h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  color: $black-color;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: $black-color;
}

h5,
.h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: $black-color;
}

p {
  font-size: 14px;
  color: $black-color;
  font-weight: 400;
  line-height: 1.3;
}

p.paragraph-small {
  overflow: hidden;
  color: #313036;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

p.paragraph-medium {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

p.paragraph-large {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.download-app-button {
  display: flex;
  height: 60px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 30px;
  background: #141414;
}

button {
  border: none;
  background: transparent;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-color;
}

.button {
  width: 100%;
  text-align: center;
  height: 60px;
  border-radius: 30px;
  background-color: $black-color;
  color: #fff;

  &:disabled {
    background: #999;
  }

  &.bordered {
    border: 1px solid $black-color;
    background: transparent;
    color: $black-color;
  }
}

.underline-link-section {
  text-align: center;
}

.underline-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  text-underline-offset: 16px;
  display: inline-block;
  padding: 16px 0;
}

.section-header {
  display: flex;
  align-items: flex-end;
  column-gap: 20px;

  & > * {
    flex: 1;
  }

  .heading-wrapper {
    display: flex;
    align-items: flex-end;
    column-gap: 35px;
    .image-wrapper {
      width: 72px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.card-mb-2 {
  margin-bottom: 20px;
}

.card-group-wrapper {
  display: flex;
  column-gap: 20px;
}

.card-wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  border-radius: 40px;
  height: 500px;
  padding: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  overflow: hidden;

  &.card-white-color {
    color: #fff;

    h3,
    p {
      color: #fff;
    }
  }

  &.card-dark-color {
    color: #141414;
  }

  &.card-large {
    background-position: right bottom;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 500px;

    h3 {
      max-width: 480px;
    }

    .icon {
      width: 72px;
      height: 72px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.avatar-wrapper {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.avatar-section-container {
  display: flex;
  gap: 10px;
  align-items: center;

  .avatar-wrapper {
    width: 40px;
    height: 40px;
  }

  .body {
    p.subtitle {
      font-size: 10px;
      color: #727272;
    }
  }
}

@media (max-width: 991.98px) {
  .second-heading {
    font-size: 40px;
    line-height: 48px;
  }
  .third-heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  h1,
  .h1 {
    font-size: 56px;
    font-weight: 700;
  }

  h2,
  .h2 {
    font-size: 40px;
    font-weight: 700;
  }

  h3,
  .h3 {
    font-size: 24px;
    font-weight: 700;
  }

  h4,
  .h4 {
    font-size: 24px;
    font-weight: 700;
  }

  h5,
  .h5 {
    font-size: 16px;
    font-weight: 500;
  }

  p.paragraph-medium {
    font-size: 14px;
    line-height: 24px;
  }
  p.paragraph-large {
    font-size: 16px;
    line-height: 26px;
  }
  .section-header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .card-group-wrapper {
    flex-direction: column;
    column-gap: 20px;
  }
  .card-wrapper {
    height: auto !important;
    display: block;

    .info-wrapper {
      h3 {
        height: auto;
      }
    }

    &.card-large {
      background-position: center top;
      .info-wrapper {
        margin-top: 236px;
      }
    }
    &.card-small {
      .info-wrapper {
        margin-top: 120px;
      }
    }
    &.card-medium {
      .info-wrapper {
        margin-top: 170px;
      }
    }
  }
}
