.faq-item {
  max-width: 850px;

  &>h2 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  &>div {
    border: #000 solid 1px;
    border-radius: 40px;
    padding: 0 40px;

    &>.item {
      border-bottom: #E8E8E8 solid 1px;

      &:nth-last-child(1) {
        border: none
      }

      &>.title-container {
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        margin-bottom: 32px;
        margin-top: 32px;
        justify-content: space-between;
      }

      &>.body {
        display: none;
      }
    }

    &>.active {
      &>.body {
        display: block;
        font-weight: 300;
        margin-bottom: 32px;
      }
    }


  }
}

@media (max-width: 991.98px) {
  .faq-item {
    &>h2 {
      font-size: 30px;
    }
  }
}
