@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-Thin.woff2') format('woff2'),
        url('../fonts/e-Ukraine-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-Light.woff2') format('woff2'),
        url('../fonts/e-Ukraine-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-UltraLight.woff2') format('woff2'),
        url('../fonts/e-Ukraine-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-Medium.woff2') format('woff2'),
        url('../fonts/e-Ukraine-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-Regular.woff2') format('woff2'),
        url('../fonts/e-Ukraine-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'e-Ukraine';
    src: url('../fonts/e-Ukraine-Bold.woff2') format('woff2'),
        url('../fonts/e-Ukraine-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

