#home {
  .personal-payments {
    background: url("./../../images/en/home/personal-payments/background.jpg")
      right top no-repeat;
    background-size: 427px auto;
    padding-top: 120px;

    .section-header {
      margin-bottom: 60px;
    }
  }
}
